'use strict';

const VIEW_FILTERS = {
    listBuildings: {
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',

        filter: null,

        currentPage: null,
        perPage: null,
    },
    listBuildingSchedules: {
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',

        filter: null,

        currentPage: null,
        perPage: null,
    },
    listBuildingControls: {
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',

        filter: null,

        currentPage: null,
        perPage: null,

        selectedStatusFilters: [0],
    },
    listBuildingPositionLacks: {
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',

        filter: null,

        currentPage: null,
        perPage: null,
        activeTab: 0,
    },
    listBuildingPositionLacksHistory: {
        sortBy: 'controlDoneDate',
        sortDesc: true,
        sortDirection: 'desc',

        filter: null,

        currentPage: null,
        perPage: null,

        selectedYear: null,
    },
};

function createViewFilters() {
    localStorage.setItem('view-filters', JSON.stringify(VIEW_FILTERS));
};

export function getByKey(key) {
    const all = getAll();

    let one = all[key] || null;

    if (!one) {
        save(key, VIEW_FILTERS[key]);
        one = VIEW_FILTERS[key];
    }

    if (one === 'null')
        return null;

    return one;
};

export function getAll() {
    const all = JSON.parse(localStorage.getItem('view-filters'));
    if (!all) {
        createViewFilters();
        return VIEW_FILTERS;
    }

    return all;
};

export function save(key, value) {
    return new Promise((resolve, reject) => {
        try {
            let all = getAll();
            all[key] = value;
            localStorage.setItem('view-filters', JSON.stringify(all));
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};